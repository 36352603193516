<template>
  <div>
    <a-card title="检验报告详情">
      <template slot="extra">
        <a-space>
          <a-button type="primary" ghost icon="printer" v-print="'#printContent'"> 打印</a-button>
          <a-button type="primary" ghost icon="left" @click="$router.go(-1)">返回</a-button>
        </a-space>
      </template>

      <section id="printContent">
        <a-spin :spinning="retrieveLoading">
          <img id="barcode" style="float: right" />
          <a-descriptions bordered>
            <a-descriptions-item label="质检单号">
              {{ dataItem.number }}
            </a-descriptions-item>
            <a-descriptions-item label="生产工单号">
              {{ dataItem.number }}
            </a-descriptions-item>
            <a-descriptions-item label="检验状态">
              {{ dataItem.inspection_status_display }}
            </a-descriptions-item>
            <a-descriptions-item label="检验方式">
              {{ dataItem.inspection_method_display }}
            </a-descriptions-item>
            <a-descriptions-item label="工序编号">
              {{ dataItem.process_number }}
            </a-descriptions-item>
            <a-descriptions-item label="工序名称">
              {{ dataItem.process_name }}
            </a-descriptions-item>
            <a-descriptions-item label="物料编号">
              {{ dataItem.goods_number }}
            </a-descriptions-item>
            <a-descriptions-item label="物料名称">
              {{ dataItem.goods_name }}
            </a-descriptions-item>
            <a-descriptions-item label="检验数量">
              {{ dataItem.inspection_quantity }}
            </a-descriptions-item>
            <a-descriptions-item label="检验人">
              {{ dataItem.inspector_name }}
            </a-descriptions-item>
            <a-descriptions-item label="检验时间">
              {{ dataItem.inspection_time }}
            </a-descriptions-item>
            <a-descriptions-item label="创建人">
              {{ dataItem.creator_name }}
            </a-descriptions-item>
            <a-descriptions-item label="创建时间">
              {{ dataItem.create_time }}
            </a-descriptions-item>
          </a-descriptions>

          <a-divider orientation="left" style="margin-top: 30px">检验项目</a-divider>
          <a-table
            rowKey="id"
            size="small"
            :columns="tableColumns"
            :dataSource="dataItem.process_inspection_point_items"
            :pagination="false"
          />
        </a-spin>
      </section>
    </a-card>
  </div>
</template>

<script>
import { processInspectionTaskRetrieve } from "@/api/inspection";
import JsBarcode from "jsbarcode";

export default {
  data() {
    return {
      dataItem: {},
      retrieveLoading: false,
      tableColumns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (_, __, index) => index + 1,
        },
        {
          title: "检测项",
          dataIndex: "point",
        },
        {
          title: "检验类型",
          dataIndex: "type_display",
        },
        {
          title: "检验工具",
          dataIndex: "tool",
        },
        {
          title: "检验标准",
          dataIndex: "standard",
        },
        {
          title: "检验描述",
          dataIndex: "description",
        },
      ],
    };
  },
  methods: {
    initData() {
      this.retrieveLoading = true;
      processInspectionTaskRetrieve({ id: this.$route.query.id })
        .then((data) => {
          this.dataItem = data;
          this.getJsBarcode(this.dataItem.number);
        })
        .finally(() => {
          this.retrieveLoading = false;
        });
    },
    getJsBarcode(number) {
      JsBarcode("#barcode", number, {
        lineColor: "#000",
        width: 2,
        height: 40,
        displayValue: true,
      });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
<style></style>
